<template>
  <Row :gutter="24" class="dashboard-console-grid">
    <Col v-bind="grid" class="ivu-mb" v-auth="['admin-user-user-index']">
      <Card :bordered="false">
        <router-link :to="{ path: '/user/list' }">
          <!--<Icon type="md-people" color="#69c0ff" />-->
          <img style="width: 94px; height: 94px;" src="@/assets/images/home/investhome/gn1.png" alt="">
          <p>订单列表</p>
        </router-link>
      </Card>
    </Col>
    <!--<Col v-bind="grid" class="ivu-mb" v-auth="['setting-system-config']">-->
    <!--  <Card :bordered="false">-->
    <!--    <router-link :to="{ path: '/setting/system_config' }">-->
    <!--      &lt;!&ndash;<Icon type="md-settings" color="#95de64" />&ndash;&gt;-->
    <!--      <img style="width: 94px; height: 94px;" src="@/assets/images/home/investhome/gn2.png" alt="">-->
    <!--      <p>投资记录</p>-->
    <!--    </router-link>-->
    <!--  </Card>-->
    <!--</Col>-->
    <Col v-bind="grid" class="ivu-mb" v-auth="['admin-store-storeProuduct-index']">
      <Card :bordered="false">
        <router-link :to="{ path: '/yunli/serviceProvidersAndServiceProviders' }">
          <!--<Icon type="md-cart" color="#ff9c6e" />-->
          <img style="width: 94px; height: 94px;" src="@/assets/images/home/investhome/gn3.png" alt="">
          <p>服务商服务商列表</p>
        </router-link>
      </Card>
    </Col>
  </Row>
</template>
<script>
export default {
  data() {
    return {
      grid: {
        xl: 4,
        lg: 6,
        md: 6,
        sm: 8,
        xs: 8,
      },
    };
  },
};
</script>
<style lang="less">
.dashboard-console-grid {
  text-align: center;
  .ivu-card-body {
    padding: 0;
  }
  i {
    font-size: 32px;
  }
  a {
    display: block;
    color: inherit;
    padding: 16px;
  }
  p {
    margin-top: 8px;
  }
}
</style>
